import 'jquery';
import 'bootstrap';
import 'bootstrap-datepicker';
import * as ko from 'knockout';

import storage from '../common/storage';
import BasePage from './basePage';
import globals from '../common/globals';
import Alert from '../common/webHostClient/alert';
import alertTemplate from "../templates/bannerAlert.html";


type CookieMode = "enable" | "disable";

export default abstract class BrandedPage extends BasePage {

    
    public static aboutUsNavPage = ".about-us-nav";
    public static whatWeOfferNavPage = ".what-we-offer-nav";
    public static pricingNavPage = ".pricing-nav";
    public static caseStudiesNavPage = ".case-studies-nav";

    readonly orderInProgress: ko.Observable<boolean> = ko.observable(!!storage.getInProgressOrderToken() || !!storage.getStartOrderSiteGeometry());
    readonly startOrContinueOrderButtonText: ko.Computed<string> = ko.computed(() => this.orderInProgress() ? "Continue search" : "Start search");

    readonly analyticsCookiesMode: ko.Observable<CookieMode> = ko.observable(null);
    readonly canSaveCookiePreferences: ko.Computed<boolean> = ko.computed(() => "enable" === this.analyticsCookiesMode() || "disable" === this.analyticsCookiesMode());
    readonly showCookiePane: ko.Observable<boolean> = ko.observable(!storage.getCookiePreferencesSaved());
    readonly showMobileNavMenu: ko.Observable<boolean> = ko.observable(false);

    readonly pageIsLoading: ko.Observable<boolean> = ko.observable(true);

    public readonly customerSupportTelephone: ko.Observable<string> = ko.observable(null);

    public readonly alerts: ko.ObservableArray<Alert> = ko.observableArray([]);

    protected async loadContentAsync() : Promise<void> {
        await super.loadContentAsync();

        const bodyElement = document.getElementsByTagName("body")[0];

        // Template snippets
        this.getTemplateHtmlSnippets().forEach(t => bodyElement.insertAdjacentHTML('beforeend', t));

        const pageDiv = document.createElement("div");
        bodyElement.appendChild(pageDiv);

        const allowAnalytics = storage.getAllowAnalyticsCookies();
        if (null != allowAnalytics)
        {
            this.analyticsCookiesMode(allowAnalytics ? "enable" : "disable");
        }  
        
        window.onscroll = this.handleHeaderMinimise;
    }

    protected async onLoadCompleteAsync(): Promise<void> {
        await super.onLoadCompleteAsync();
        
        this.customerSupportTelephone(globals.config.customerSupportTelephone);
        this.pageIsLoading(false);


        // Uncomment to check the Bootstrap elements are structured correctly at run time.
        //this.checkBootstrapElementStructure();
    }

    protected getTemplateHtmlSnippets(): string[] 
    {
        return [ alertTemplate ];
    }

    public saveCookiePreferences() : void {

        if (!this.canSaveCookiePreferences())
        {
            return;
        }

        storage.setAllowAnalyticsCookies("enable" === this.analyticsCookiesMode());
        storage.setCookiePreferencesSaved(true);

        this.showCookiePane(false);
    }

    public showCookieSettings(): void {
        this.showCookiePane(true);
    }

    public toggleMobileNavMenu(): void{
        // Avoid double scroll bars
        !this.showMobileNavMenu() ? $('body').addClass('overflow-y-hidden') : $('body').removeClass('overflow-y-hidden');

        this.showMobileNavMenu(!this.showMobileNavMenu());
    }

    public acceptAllAndSaveCookiePreferences() : void {
        
        this.analyticsCookiesMode("enable");
        this.saveCookiePreferences();
    }

    public handleHeaderMinimise() {
        // Ignore minimise on small screens
        if (document.body.clientWidth >= 1200 && (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50)) {
            document.getElementById("nav").classList.add("minimise");
            document.getElementById("sub-padding").classList.add("minimise");
        } else {
            document.getElementById("nav").classList.remove("minimise");
            document.getElementById("sub-padding").classList.remove("minimise");
        }
    }

    public setActiveNav(target) {
        $(target).addClass('active-page');
    }



    private checkBootstrapElementStructure(): void {
        
        $("div").each((index: number, divElement: Element) => {

            if (this.isBootstrapColumnElement(divElement))
            {
                this.checkBootstrapColumnElement(divElement);
            }

            if (this.isBootstrapRowElement(divElement))
            {
                this.checkBootstrapRowElement(divElement);
            }

            // if (this.isBootstrapContainerElement(divElement))
            // {
            //     this.checkBootstrapContainerElement(divElement);
            // }
        });
    }

    
    private isBootstrapContainerElement(element: Element): boolean {

        const classAttr = element.getAttribute("class");
        if (!classAttr)
        {
            return false;
        }

        const classList: Array<string> = classAttr.split(" ");

        let isContainer = classList.some((v, i, a) => v === "container");

        return isContainer;
    }

    private isBootstrapRowElement(element: Element): boolean {

        const classAttr = element.getAttribute("class");
        if (!classAttr)
        {
            return false;
        }

        const classList: Array<string> = classAttr.split(" ");

        let isRow = classList.some((v, i, a) => v === "row" || v === "responsive-row");

        return isRow;
    }


    private isBootstrapColumnElement(element: Element): boolean {

        const classAttr = element.getAttribute("class");
        if (!classAttr)
        {
            return false;
        }

        const classList: Array<string> = classAttr.split(" ");

        let isCol = classList.some((v, i, a) => v.startsWith("col-"));

        return isCol;
    }


    private checkBootstrapColumnElement(colElement: Element): boolean {

        console.log("Checking col element", colElement);
        let valid: boolean = false;

        // Column element should have a row element as an ancestor, if it has a column or container ancestor that must be more distant than the closest row ancestor.

        let ancestor: Element = colElement.parentElement;

        while (ancestor)
        {

            if (this.isBootstrapContainerElement(ancestor))
            {
                console.error("Detected column element with no row ancestor (container)", colElement);
                valid = false;
                break;
            }

            if (this.isBootstrapColumnElement(ancestor))
            {
                console.error("Detected column element with no row ancestor (column)", colElement);
                valid = false;
                break;
            }

            if (this.isBootstrapRowElement(ancestor))
            {
                // found ancestor row
                valid = true;
                break;
            }

            ancestor = ancestor.parentElement;
        }

        if (!valid)
        {
            console.error("Detected column element with no row ancestor (none)", colElement);
            return false;
        }

        console.log("Checked col element", colElement);
        return valid;
    }

    private checkBootstrapRowElement(rowElement: Element): boolean {

        console.log("Checking row element", rowElement);
        let valid: boolean = false;

        // Row element should have a container or column element as an ancestor.

        let ancestor: Element = rowElement.parentElement;

        while (ancestor)
        {
            if ( this.isBootstrapRowElement(ancestor))
            {
                console.error("Detected row element with no column or container ancestor (row)", rowElement);
                valid = false;
                break;
            }

            if (this.isBootstrapContainerElement(ancestor) || this.isBootstrapColumnElement(ancestor))
            {
                // found ancestor container or column
                valid = true;
                break;
            }

            ancestor = ancestor.parentElement;
        }

        if (!valid)
        {
            console.error("Detected row element with no column or container ancestor (none)", rowElement);
            return false;
        }

        console.log("Checked row element", rowElement);
        return valid;
    }
}